<template>
  <div class="yxq-body">
    <!--错误提示 -->
    <div class="ysw-error" v-if="error">
      <van-image :src="require('../../../public/otherImg/empty-image-default.png')" width="180px"/>
      <p>{{ errorMsg }}</p>
    </div>
    <!--加载-->
    <van-loading size="24px" vertical v-else-if="loading">
      加载中...
    </van-loading>
    <!--展示的数据-->
    <div v-else>
      <van-sticky>
        <van-nav-bar :title="examinationName" v-if="examType=='ordinary'"/>
        <van-nav-bar :title="testName" v-else/>
      </van-sticky>
      <div class="yxq-exam">
        <van-circle v-model="test.studentTotalScoreRate" :rate="test.studentTotalScoreRate" :speed="100" text=""
                    size="150"><!-- layer-color="#f7f8fa"-->
          <div style="font-size: 40px;margin-top: 25px;color: #28a745;font-weight: bolder;">{{ test.rank }}</div>
          <div>
            <span style="font-size: 25px">{{ test.studentTotalScore }}</span>
            <span>/{{ test.fullMark }}</span>
          </div>
        </van-circle>
        <van-row>
          <van-col span="24">
            <div style="font-size: 20px;">{{ test.testAverage }}</div>
            <div style="font-size: 12px;">试卷均分</div>
          </van-col>
          <!--                    <van-col span="6">-->
          <!--                        <div style="font-size: 20px;">{{test.rankMedian}}</div>-->
          <!--                        <div style="font-size: 12px;">中位数</div>-->
          <!--                    </van-col>-->
          <!--<van-col span="12">
              <div style="font-size: 20px;">{{test.rankingClass}}</div>
              <div style="font-size: 12px;">班排</div>
          </van-col>-->
          <!--                    <van-col span="6">-->
          <!--                        <div style="font-size: 20px;">{{test.rankingCampus}}</div>-->
          <!--                        <div style="font-size: 12px;">年排</div>-->
          <!--                    </van-col>-->
        </van-row>
        <div class="van-clearfix"></div>
      </div>

      <!--历次对比-->
      <van-swipe :loop="false" v-if="!previousComparisonsChart.error">
        <van-swipe-item v-if="false">
          <!--历次等级图表-->
          <ve-histogram :data="previousComparisonsChart.ranking"
                        :extend="previousComparisonsChart.extendRanking"
                        :loading="previousComparisonsChart.loading"
                        height="280px" width="96vw" style="margin: auto;"></ve-histogram>
        </van-swipe-item>
        <van-swipe-item>
          <!--历次等级图表 :extend="previousComparisonsChart.extendScore" :data="previousComparisonsChart.score"-->
          <ve-histogram :loading="previousComparisonsChart.loading"
                        :series="previousComparisonsChart.score.series"
                        :xAxis="previousComparisonsChart.score.xAxis"
                        :yAxis="previousComparisonsChart.score.yAxis"
                        :legend="previousComparisonsChart.score.legend"
                        height="280px" width="96vw" style="margin: auto;"></ve-histogram>
        </van-swipe-item>
      </van-swipe>
      <!-- 知识点分析-->
      <div class="analysis_box" v-if="kp_tag_analysis.length>0">
        <div class="analysis_item">
          <div class="analysis_block">
            <div class="item_title">知识点掌握情况</div>
            <div class="item_content">
              <van-row class="kpAnalysisTitle">
                <van-col :span="8">
                  <span>知识点</span>
                </van-col>
                <van-col :span="12"></van-col>
                <van-col :span="4"><span>成就度</span></van-col>
              </van-row>
              <van-row v-for="kp in kp_tag_analysis" :key="kp.id" class="kpAnalysis">
                <van-col :span="8" class="kpName">
                  <span>{{ kp.name }}</span>
                </van-col>
                <van-col :span="8" class="kpSlider">
                  <van-slider :disabled="true" :active-color="kpSliderColorInName(kp.total_score_rate,'color')"
                              button-size="0px" bar-height="10" v-model="kp.total_score_rate"/>
                  <!--                                    <van-slider :active-color="'#F9B723'" button-size="0px" bar-height="10" v-model="kp.total_score_rate"/>-->
                </van-col>
                <van-col :span="4" class="kpName">
                  {{ kp.total_score_rate }}%
                </van-col>
                <van-col :span="4" class="kpName">
                  {{ kpSliderColorInName(kp.total_score_rate, 'name') }}
                </van-col>
              </van-row>
              <!--<div class="analysis_line" v-for="(item,index) in question_type_analysis" :key="index">
                  <div class="line_item"><span class="line_label">{{item.name}}：</span>{{item.student_total_score}} /
                      {{item.total_score}}
                  </div>
                  <div class="line_item"><span class="line_label">得分率：</span>{{item.total_score_rate}}%
                  </div>
                  <div class="line_item" v-html="calcRateText(item.level,item.level_name)">
                  </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>

      <!--题目分析-->
      <div v-for="(item,index) in questions.data" :key="index">
        <van-divider content-position="left" v-if="index=='estimateIncreaseScore' && item.questionCount>0">
          {{ item.title }}（可提升{{ item.questionCount }}分，共{{ item.questionCount }}题）
          <br/>和你水平相当的同学多数都做对了，加油哦！
        </van-divider>
        <van-divider content-position="left" v-else-if="index!='estimateIncreaseScore'&&item.questions.length>0">
          {{ item.title }}
        </van-divider>
        <div class="questionShowOrder"
             v-if="(index=='estimateIncreaseScore' && item.questionCount>0) || (index!='estimateIncreaseScore'&&item.questions.length>0)">
          <div v-for="(question,questionIndex) in item.questions" :key="questionIndex" span="3" :class="index">
            <div>{{ question.showOrder }}</div>
            <div>{{ question.rate }}%</div>
          </div>
          <div></div>
        </div>
      </div>

      <a class="showQuestion" v-on:click="goQuestionList()" v-if="!questions.error">查看题目</a>
    </div>

  </div>
</template>

<script>
import {examinationAnalysisApi} from '@/api/index'

export default {
  name: "studentTestPaper",
  data() {
    return {
      loading: false,
      examType: 'unified',//ordinary单科；unified大考
      params: {
        examinationid: this.$route.query.examinationId,
        testid: this.$route.query.testId,
        schoolid: this.$route.query.schoolId,
        studentid: this.$route.query.studentId,
        type: '',
        reciprocal: 5,//从当次考试算，去5个考试
      },
      studentScoreData: {
        name: undefined,
        username: undefined,
        real_name: undefined,
        student_total_score: 0,
        total_score: 0,
        total_score_rate: 0,
        test_id: undefined,
        examination_id: undefined,
      },
      collapseActiveName: '1',
      currentRate: 0,

      error: false,
      errorMsg: '找不到数据',
      //知识点分析
      kp_tag_analysis: [],
      examinationName: '',//考试名称
      testName: '',//科目名称
      testImg: '',
      className: '',//学生班级
      //试卷分析
      test: {
        fullMark: 120,//满分
        testAverage: 0,//试卷均分
        studentTotalScore: 0,//学生得分
        studentTotalScoreRate: 0,//学生得分率
        rank: '',//学生等级
        rankMedian: 0,//等级中位数
        rankAverage: 0,//等级均分
        rankingClass: 0,//学生班排
        rankingCampus: 0,//学生年级排名
        rankingSchool: 0,//学生全校排名
      },
      //题目分析
      questions: {
        data: [],
        error: true,
      },
      previousComparisonsChart: {
        error: true,
        loading: false,
        score: {
          series: [{
            type: 'scatter',
            name: '学生成绩',
            label: {
              normal: {show: true, formatter: "{c}分", position: "top"},
            },
            data: []
          }, {
            type: 'line',
            name: '等级中位数',
            label: {
              normal: {show: true, formatter: "{c}分", position: "top"},
            },
            data: []
          }],
          xAxis: {data: []},
          yAxis: {type: "value", max: 100, min: 0},
          legend: {show: true,},
        },
        ranking: {columns: ['次数', '学生校排'], rows: []},
        extendRanking: {
          series: {
            type: 'line',
            label: {
              normal: {show: true}
            }
          },
          'yAxis.0.inverse': true,
        }
      }
    }
  },
  mounted() {
    // this.singleTestAnalysis()
  },
  activated() {
    this.singleTestAnalysis();
    let pp = {testId: "14224"};
    examinationAnalysisApi.getStudentAnalysis(pp).then(res => {
      if (res.success) {
        console.log(res.data);
      }
    })
  },
  beforeDestroy(){
    console.log("初始化")
    this.previousComparisonsChart = {
      error: true,
      loading: false,
      score: {
        series: [{
          type: 'scatter',
          name: '学生成绩',
          label: {
            normal: {show: true, formatter: "{c}分", position: "top"},
          },
          data: []
        }, {
          type: 'line',
          name: '等级中位数',
          label: {
            normal: {show: true, formatter: "{c}分", position: "top"},
          },
          data: []
        }],
        xAxis: {data: []},
        yAxis: {type: "value", max: 100, min: 0},
        legend: {show: true,},
      },
      ranking: {columns: ['次数', '学生校排'], rows: []},
      extendRanking: {
        series: {
          type: 'line',
          label: {
            normal: {show: true}
          }
        },
        'yAxis.0.inverse': true,
      }
    }
  },
  deactivated() {
    console.log("页面失活");
    this.kp_tag_analysis = []
    this.previousComparisonsChart = {
      error: true,
      loading: false,
      score: {
        series: [{
          type: 'scatter',
          name: '学生成绩',
          label: {
            normal: {show: true, formatter: "{c}分", position: "top"},
          },
          data: []
        }, {
          type: 'line',
          name: '等级中位数',
          label: {
            normal: {show: true, formatter: "{c}分", position: "top"},
          },
          data: []
        }],
        xAxis: {data: []},
        yAxis: {type: "value", max: 100, min: 0},
        legend: {show: true,},
      },
      ranking: {columns: ['次数', '学生校排'], rows: []},
      extendRanking: {
        series: {
          type: 'line',
          label: {
            normal: {show: true}
          }
        },
        'yAxis.0.inverse': true,
      }
    }

  },
  destroyed() {
    console.log("页面销毁");
  },
  methods: {
    singleTestAnalysis() {//单份试卷的分析
      this.loading = true;

      this.questions.data = []
      this.questions.error = true

      examinationAnalysisApi.singleTestAnalysis(this.params).then(res => {
        this.loading = false;

        if (res.success) {
          this.className = res.data.className;
          this.examinationName = res.data.examinationName;
          this.testName = res.data.subjectName;
          this.test = {
            fullMark: res.data.testTotalScore,//满分
            testAverage: res.data.testAverage,//试卷均分
            studentTotalScore: res.data.studentTotalScore,//学生得分
            studentTotalScoreRate: (res.data.studentTotalScore / res.data.testTotalScore * 100),//学生得分率
            rank: res.data.studentRank,//学生等级
            rankMedian: 0,//等级中位数
            rankAverage: 0,//等级均分
            rankingClass: res.data.studentRankingClass,//学生班排
            rankingCampus: res.data.studentRankingCampus,//学生年级排名
            rankingSchool: res.data.studentRankingSchool,//学生全校排名
          };
          if (res.data.testRankAnalysis) {
            for (let item of res.data.testRankAnalysis) {
              if (item.rank == this.test.rank) {
                this.test.rankMedian = item.median;
                this.test.rankAverage = item.average;
                break;
              }
            }
          }
          if (parseInt(res.examinationType) == 1) {
            this.questionAnalysis();
            this.examType = 'ordinary';
          } else {
            this.studentPreviousComparisonsRank();
          }
        } else {
          // this.error = true;
          // this.errorMsg = res.msg;
        }
      });
    },
    studentPreviousComparisonsRank() {//历次对比
      this.params.type = 'singleExam';
      this.previousComparisonsChart.loading = true;

      examinationAnalysisApi.studentPreviousComparisonsRank(this.params).then(res => {
        if (res.success && res.data.length !== 0) {
          let max = 0, min = -1;
          for (let item of res.data) {
            this.previousComparisonsChart.ranking.rows.push({
              '次数': item.name,
              '学生校排': item.ranking
            });

            this.previousComparisonsChart.score.xAxis.data.push(item.name);
            this.previousComparisonsChart.score.series[0].data.push(item.totalScore);
            this.previousComparisonsChart.score.series[1].data.push(item.median);

            if (item.totalScore > max) {
              max = item.totalScore;
            }
            if (item.median > max) {
              max = item.median
            }
            if (min == -1) {
              min = item.totalScore;
            }
            if (item.totalScore < min) {
              min = item.totalScore;
            }
            if (item.median < min) {
              min = item.median
            }
          }
          this.previousComparisonsChart.score.yAxis.max = max;
          this.previousComparisonsChart.score.yAxis.min = min;
          this.previousComparisonsChart.error = false;
        } else {
          // this.previousComparisonsChart.error = true;
        }

        this.previousComparisonsChart.loading = false;
        this.questionAnalysis();
      });
    },
    questionAnalysis() {//题目分析
      this.params.type = '';
      examinationAnalysisApi.questionAnalysis(this.params).then(res => {
        if (res.success) {
          if (typeof (res.data) === 'object' && res.data.data) {
            this.questions.error = false;
            this.questions.data = res.data.data;
          }
        }
      });
      let curparams = {
        testId: this.params.testid
      };
      console.log("aaaaaaaaaaaaaaaaaaaaaaaa");
      examinationAnalysisApi.getStudentAnalysis(curparams).then(res => {
        if (res.success) {
          this.studentScoreData.name = res.data.name;
          this.studentScoreData.username = res.data.username;
          this.studentScoreData.real_name = res.data.real_name;
          this.studentScoreData.total_score = res.data.total_score;
          this.studentScoreData.total_score_rate = res.data.total_score_rate;
          this.studentScoreData.student_total_score = res.data.student_total_score;
          this.studentScoreData.examination_id = res.data.examination_id;
          this.studentScoreData.test_id = res.data.test_id;
          // this.$echarts.init(this.$refs.radar)
          // if(res.data.difficulty_list && res.data.difficulty_list.length > 0){
          this.difficulty_list = res.data.difficulty_list;
          // }

          this.error_cause_analysis = res.data.error_cause_analysis;
          // if(res.data.error_cause_analysis && res.data.error_cause_analysis.length > 0){
          //   this.reasonDraw();
          // }

          this.question_type_analysis = res.data.question_type_analysis;
          if (res.data.question_type_analysis && res.data.question_type_analysis.length > 0) {
            // if (this.question_type_analysis.length > this.radarStartNum) {
            //   this.radarDraw();
            // } else {
            //   this.analysisBarDraw();
            // }
          }

          if (res.data.kp_analysis && res.data.kp_analysis.length > 0) {
            this.kp_tag_analysis = res.data.kp_analysis
          }
          console.log(this.kp_tag_analysis)

          // if(res.data.study_proposal && res.data.study_proposal.length > 0){
          this.study_proposal = res.data.study_proposal;
          // }

          // // if(res.data.sure_up_score.list.length > 0){
          // this.sure_up_score.count_min_up_score = res.data.sure_up_score.count_min_up_score;
          // this.sure_up_score.count_max_up_score = res.data.sure_up_score.count_max_up_score;
          // this.sure_up_score.list = res.data.sure_up_score.list;
        } else {
          console.log('错误');
        }
      })
    },
    goBack() {//返回
      console.log("返回")
      history.back()
    },
    goQuestionList() {//跳转题目列表
      this.$router.push({
        name: 'titleList',
        query: {
          correctable: 'analyzed',
          examinationId: this.params.examinationId,
          testId: this.params.testid,
          schoolId: this.params.schoolId,
          studentId: this.params.studentId
        }
      })
    },
    kpSliderColorInName(scoreRate, type) {
      let color = '', text = '';
      if (scoreRate >= 95) {
        color = '#36BB66'
        text = '优秀'
      } else if (scoreRate >= 86) {
        color = '#F9B723';
        text = '中等';
      } else if (scoreRate >= 70) {
        color = '#EE7F26';
        text = '一般';
      } else {
        color = '#FF0000';
        text = '待提高';
      }
      return type === 'color' ? color : text;
    }
  },
  watch: {
    $route(to, from) {
      this.params.examinationid = to.query.examinationId
      this.params.testid = to.query.testId
      this.params.schoolid = to.query.schoolId
      this.params.studentid = to.query.studentId
      // this.singleTestAnalysis()
    }
  },
  filters: {}
}
</script>

<style lang="scss" scoped>
.yxq-body {
  background-color: #f7f8fa !important;
  min-height: 100vh;
  height: 100%;
  .analysis_box {
    @extend .flex_height_auto;
    padding: 0 16px;
    margin: 12px 0;
    overflow: auto;
    height: 100%;
    overflow-y: auto;
    .analysis_item {
      @extend .flex_height_auto;
      padding: 12px;
      margin-bottom: 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      height: 100%;
      .analysis_block {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .mark_remark {
          font-weight: normal;
          color: rgba(255, 101, 92, 1);
        }

        .item_title {
          font-size: 16px;
          font-weight: bold;
          color: rgba(1, 159, 232, 1);
          text-align: left;
          padding-bottom: 8px;
        }

        .item_content {
          text-align: left;

          .weakness_item {
            font-size: 12px;
            color: rgba(51, 51, 51, 1);
            font-weight: bold;
            margin-bottom: 4px;

            &.weight {
              font-weight: bold;
            }

          }

          .canvas_item {
            height: 315px;
            width: 100%;
            /*background: #999;*/
          }

          .analysis_line {
            @extend .flex_row_center;

            .line_item {
              flex: 2;

              .line_label {
                color: #999;
              }

              &:not(:last-child) {
                margin-right: 12px;
              }

              &:last-child {
                flex: 1;
                text-align: right;
              }
            }

            .rateText {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }


    }
  }

  .kpAnalysisTitle {
    text-align: center;
    padding: 5px;
    border-bottom: 1px dashed rgba(1, 159, 232, 1);
    span{
      font-size: 18px;
      font-weight: bold;
      color: rgba(1, 159, 232, 1);
    }
  }
  .kpAnalysis{
    color: rgba(51, 51, 51, 100);
    font-size: 13px;
    text-align: center;
    font-family: PingFangSC-regular;
    padding: 5px;
    border-bottom: 1px dashed rgba(187, 187, 187, 45);
    height: 40px;

    .kpSlider{
      padding: 15px 10px;
      .van-slider--disabled{
        opacity: 1;
      }
    }
    .kpName {
      font-size: 15px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      //line-height: 35px;
    }
  }
  .yxq-exam {
    text-align: center;
    padding-top: 25px;
    margin-bottom: 15px;

    .van-circle {
      vertical-align: middle;
      margin-bottom: 15px;
    }

    .van-row {
      box-shadow: 1px 1px 5px #d4d3d3;
      width: 97%;
      margin: auto;
      border-radius: 5px;
      padding: 15px 0px;
      background-color: #ffffff;

      .van-col {
        border-right: 1px solid #ececec;
      }

      .van-col:last-child {
        border-right: 0px;
      }
    }
  }

  .questionShowOrder {
    text-align: center;
    width: 92%;
    margin: auto;
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 1px 1px 5px #d4d3d3;


    > div {
      border: 1px solid #c0c4cc;
      margin-bottom: 10px;
      margin-right: 8px;
      border-radius: 5px;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
      min-width: 25px;

      div:first-child {
        font-size: 14px;
        font-weight: bolder;
      }

      div:last-child {
        font-size: 11px;
      }
    }

    > div:last-child {
      float: inherit;
      clear: both;
      border: 0px;
      padding: 0;
    }

    .outstanding {
      color: #28a745;
      border: 1.5px solid #28a745;
    }

    .noPassed {
      color: #dc3545;
      border: 1.5px solid #dc3545;
    }

    .passed {
      color: #ffae00;
      border: 1.5px solid #ffae00;
    }

    .estimateIncreaseScore {
      color: #17a2b8;
      border: 1.5px solid #17a2b8;
    }
  }

  .showQuestion {
    position: fixed;
    z-index: 9999;
    bottom: 60px;
    right: 0;
    width: 20px;
    text-align: center;
    background: #63aeea;
    padding: 5px;
    color: #fff;
    font-size: 12px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  ::v-deep .van-swipe {
    background: #fff;
    width: 96vw;
    margin: auto;
    padding: 8px 0px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #d4d3d3;
  }

  ::v-deep .van-panel {
    margin-bottom: 15px;

    .van-panel__content {
      padding: 10px 5px;
    }
  }

  ::v-deep .van-loading {
    justify-content: center;
    height: 90vh;
  }

  .ysw-error {
    height: 60vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;

    p {
      margin: 0px;
      color: #969799;
      font-size: 14px;
      vertical-align: middle;
    }
  }
}
</style>
