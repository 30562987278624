/*
 * @Author: 艾斯
 * @Date: 2020-02-20 16:04:10
 * @LastEditTime : 2020-02-20 21:42:25
 * @LastEditors  : Please set LastEditors
 * @Description: 微信api
 * @FilePath: /vue/yunxue/src/api/class/task.js
 */
import { post } from "../http";

export default {
  // 获取js-sdk 签名
  getSignature: params => {
    return post(
      `/index.php?r=mobileapp/profile/getSignature`, params
    );
  },
}