/*
 * @Author: 艾斯
 * @Date: 2020-02-20 10:43:33
 * @LastEditTime : 2020-02-20 16:08:49
 * @LastEditors  : Please set LastEditors
 * @Description: API列表
 * @FilePath: /vue/yunxue/src/api/index.js
 */
import login from "./class/login";
import task from "./class/task"
import examinationAnalysis from  "./class/examinationAnalysis"
import weixin  from "./class/weixin.js"

const loginApi = login;
const taskApi = task;
const examinationAnalysisApi = examinationAnalysis;
const wxApi = weixin
export { loginApi, taskApi, wxApi, examinationAnalysisApi};
