/*
 * @Author: 艾斯
 * @Date: 2020-02-20 16:04:10
 * @LastEditTime : 2020-02-20 21:42:25
 * @LastEditors  : Please set LastEditors
 * @Description: 作业api
 * @FilePath: /vue/yunxue/src/api/class/task.js
 */
import { post } from "../http";

export default {
  // 获取学生的考试列表
  userExamIsStudent: params => post(`/index.php?r=mobileapp/examPublicList/userExamIsStudent`, params),
  // 获取年级列表
  getGrades: params => {
    return post(
      `/index.php?r=mobileapp/utils/getGrades`, params
    );
  },
  // 获取科目列表
  getSubjects: params => {
    return post(
      `/index.php?r=mobileapp/utils/getSubjects`, params
    );
  },
  // 获取学生一份试卷情况
  getStudentTestPaper: params => {
    return post(
      `/index.php?r=mobileapp/examinationWebCorrection/getStudentTestPaper`, params
    );
  },  // 获取学生一份试卷情况
  getStudentTestPaperNew: params => {
    return post(
      `/index.php?r=mobileapp/examinationWebCorrection/getStudentTestPaperNew`, params
    );
  },
  // 提交学生一份试卷的批改情况
  postCorrectionResults: params => {
    return post(
      `/index.php?r=mobileapp/examinationWebCorrection/postCorrectionResults`, params
    );
  },
  // 获取学生的一份试卷的题目列表及本人的题目分析
  studentTestPaperQuestion: params => {
    return post(
      `/index.php?r=mobileapp/examPublicList/studentTestPaperQuestion`, params
    );
  },

  //精准作业批改 提交批改答案
  submitTestCorrectionAnswer: params => {
    return post(
      `/index.php?r=mobileapp/examinationWebCorrection/submitTestCorrectionAnswer`, params
    );
  },
}