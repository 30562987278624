/*
 * @Author: 杨月玲
 * @Date: 2020-05-25
 * @Description: 考试分析
 * @FilePath: /vue/yunxueqing/src/api/class/examinationAnalysis.js
 */
import { get,post } from "../http";

export default {
    examinationInfo:params=>{
        return post(
            `/index.php?r=mobileapp/examAnalysis/examinationInfo`, params
        );
    },
    singleTestAnalysis:params=>{// 单份试卷的分析
        return post(
            `/index.php?r=mobileapp/examAnalysis/singleTestAnalysis`, params
        );
    },
    questionAnalysis:params=>{// 试卷的题目分析
        return post(
            `/index.php?r=mobileapp/examAnalysis/questionAnalysis`, params
        );
    },
    singleStudentAnalysis: params => {// 学生单个考试分析
        return post(
            `/index.php?r=mobileapp/examAnalysis/singleStudentAnalysis`, params
        );
    },
    studentPreviousComparisonsRank:params=>{
        return post(
            `/index.php?r=mobileapp/examAnalysis/studentPreviousComparisonsRank`, params
        );
    },

    getStudentAnalysis:params => {
        return post(
            `/index.php?r=mobileapp/specialPlanAnalysis/GetExamAnalysis`, params
        );
    }
}