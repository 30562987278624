/*
 * @Author: 艾斯
 * @Date: 2020-02-20 10:44:00
 * @LastEditTime : 2020-02-21 23:09:06
 * @LastEditors  : Please set LastEditors
 * @Description: 登录API
 * @FilePath: /vue/yunxue/src/api/class/login.js
 */
import { post } from "../http";

export default {
  // 获取学校列表
  getSchools: params => {
    return post(
      `/index.php?r=mobileapp/utils/getSchools`, params
    );
  },
  // 获取校区列表
  getCampus: params => {
    return post(
      `/index.php?r=mobileapp/utils/getCampus`, params
    );
  },
  // 获取年级级别列表
  getGradeLevel: params => {
    return post(
      `/index.php?r=mobileapp/utils/getGradeLevel`, params
    );
  },
  // 获取班级列表
  getClasses: params => {
    return post(
      `/index.php?r=mobileapp/utils/getClasses`, params
    );
  },
  // 用户登录
  login: params => {
    return post(
      `/index.php?r=mobileapp/profile/login`, params
    );
  },
  // 用户注销
  closeLogin: params => {
    return post(
      `/index.php?r=mobileapp/profile/closeLogin`, params
    );
  },
  // 获取用户信息
  userInfo: params => {
    return post(
      `/index.php?r=mobileapp/profile/userInfo`, params
    );
  },
  // 修改密码
  updatePwd: params => {
    return post(
      `/index.php?r=mobileapp/profile/updatePwd`, params
    );
  },
  // 获取手机验证码
  sendBindPhoneVerifyCode: params => {
    return post(
      `/index.php?r=mobileapp/profile/sendBindPhoneVerifyCode`, params
    );
  },
  // 绑定微信
  bindWeChat: params => {
    return post(
      `/index.php?r=mobileapp/profile/bindWeChat`, params
    );
  },
}